import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Option } from '../../../fields/Autocomplete/types';
import { Awaited } from '../../../../../types';
import { AutocompleteRequestRunner } from '@lib/Components/SearchForm/hooks/autocomplete/request-runner';

export const createAsyncAutocomplete = <T extends AutocompleteRequestRunner>(
  runner: T,
  limitQueryLength: number = 3
) => {
  // todos add react query wrapper for runner run
  return function useAsyncAutocomplete(
    defaultOptions: Option<any>[] = []
  ): [Awaited<ReturnType<T['run']>>, boolean, (e: React.FormEvent<HTMLInputElement>, extra?: any) => void] {
    const [options, setOptions] = useState<Option<any>[]>(defaultOptions);
    const [isLoading, setLoading] = useState(false);

    const debouncedChangeAutocomplete = useDebouncedCallback(async (query: string, extra) => {
      if (limitQueryLength) {
        if (!query) return setOptions([]);
        if (query.length < limitQueryLength) return;
      }

      setLoading(true);

      try {
        const runnerOptions = await runner.run({ query, extra });
        setOptions(runnerOptions);
      } finally {
        setLoading(false);
      }
    }, 250);

    return [
      options as any,
      isLoading,
      (e, extra) => {
        if (e) {
          debouncedChangeAutocomplete(e.currentTarget.value, extra);
        }
      },
    ];
  };
};
