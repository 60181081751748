import { Nullable } from 'types';

export interface RegionAPI {
  id: number;
  name: string;
  deletedAt: Nullable<string>;
  parentId: Nullable<number>;
  parent: RegionAPI;
  lt: number;
  ld: number;
  population: number;
  type: RegionTypeEnumAPI;
  oktmo: Nullable<string>;
}

export enum RegionTypeEnumAPI {
  G = 1,
  S,
  P,
  D,
  H,
  A,
  PGT,
  ST,
}
