import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, FieldProps, useFormikContext } from 'formik';
import MuiAutocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { useDebouncedCallback } from 'use-debounce';
import { FieldWrapper, Error } from '../styled';
import { Props, Option } from './types';
import { Tag, TextField } from './styled';
import { Nullable } from 'types';
const DEFAULT_NO_OPTIONS_TEXT = 'Начните вводить';

const Autocomplete = <T,>({
  name,
  label = '',
  textFieldProps = {},
  multiple,
  limitTags,
  variant = 'filled',
  afterSelectAction,
  clearOnBlur = false,
  openOnFocus,
  withFormikValue,
  inputRef,
  getOptionLabel,
  inputAutoComplete,
  ...rest
}: Props<T>) => {
  const [noOptionsText, setNoOptionsText] = useState(DEFAULT_NO_OPTIONS_TEXT);
  const formik = useFormikContext();
  const formikValue = formik.getFieldMeta(name).value as string;
  const [valueFromForm, setValueFromForm] = useState<Nullable<string>>(formikValue);

  useEffect(() => {
    setValueFromForm(cur => {
      if (cur !== formikValue) {
        return formikValue;
      }

      return cur;
    });
  }, [formikValue]);

  const onInputChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2) {
      setNoOptionsText('Ничего не найдено');
    } else {
      setNoOptionsText(DEFAULT_NO_OPTIONS_TEXT);
    }
  }, 250);

  const onInputBlur = () => {
    setNoOptionsText(DEFAULT_NO_OPTIONS_TEXT);
  };

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const hasError = meta.touched && !!meta.error;

        const renderOption: (option: Option, state: AutocompleteRenderOptionState) => React.ReactNode = option => {
          return (
            <div key={option.value} style={{ display: 'block' }}>
              <div>{option.label}</div>
              {option.hint && <div style={{ fontSize: '0.75rem', opacity: 0.6 }}>{option.hint}</div>}
            </div>
          );
        };

        const renderOptionLabel = (option: Option<T>) => {
          if (getOptionLabel) {
            return getOptionLabel(option);
          }

          return `${option.label}${option.hint ? ' ' + option.hint : ''}`;
        };

        const renderTags: (value: Option[], getTagProps: AutocompleteGetTagProps) => React.ReactNode = (
          tagValue,
          getTagProps
        ) => {
          return tagValue.map((option, index) => (
            <Tag key={option.value} {...getTagProps({ index })} label={option.hint} />
          ));
        };

        const renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode = params => (
          <TextField
            {...params}
            {...textFieldProps}
            onChange={onInputChange}
            onBlur={onInputBlur}
            label={label}
            variant={variant}
            error={hasError}
            helperText={!hasError && textFieldProps.helperText}
            inputRef={inputRef}
            autoComplete={inputAutoComplete}
          />
        );

        return (
          <FieldWrapper hasError={hasError}>
            <MuiAutocomplete
              {...rest}
              {...field}
              id={name}
              multiple={multiple}
              renderTags={renderTags}
              renderInput={renderInput}
              renderOption={renderOption}
              loadingText="Загрузка ..."
              noOptionsText={noOptionsText}
              limitTags={1}
              getOptionLabel={renderOptionLabel}
              onChange={(_, value: Option<T>) => {
                form.setFieldValue(name, value);

                if (afterSelectAction && value) {
                  afterSelectAction(value);
                }
              }}
              getOptionSelected={(option, value) => {
                if (withFormikValue) {
                  return option.value === valueFromForm;
                }

                return option.value === value.value;
              }}
              clearOnBlur={clearOnBlur}
              style={{
                position: multiple ? 'absolute' : 'inherit',
                zIndex: multiple ? 100 : 99,
              }}
              filterOptions={name === 'fkko' && rest.filterOptions === undefined ? (x, _) => x : rest.filterOptions}
              openOnFocus={openOnFocus}
            />
            <ErrorMessage name={name} component={Error} />
          </FieldWrapper>
        );
      }}
    </Field>
  );
};

export default Autocomplete;
