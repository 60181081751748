import { config } from '../../config';
import { companyNameInnDadata, companyOkvedDadata, personFioDadata } from './endpoints';

export const getCompanyNameInnDadata = async ({ query, signal }: { query: string; signal?: AbortSignal }) => {
  return await fetch(companyNameInnDadata, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${config().dadata.token}`,
    },
    body: JSON.stringify({ query }),
    signal,
  });
};

export const getCompanyOkvedDadata = async ({ query, signal }: { query: string; signal?: AbortSignal }) => {
  return await fetch(companyOkvedDadata, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${config().dadata.token}`,
    },
    body: JSON.stringify({ query }),
    signal,
  });
};

export const getPersonFioDadata = async ({ query }: { query: string }) => {
  return await fetch(personFioDadata, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${config().dadata.token}`,
    },
    body: JSON.stringify({ query }),
  });
};
