import { CompanyShortContactorsAPI } from '@http/models/api/company';
import { Option } from '@lib/Components/fields/Autocomplete/types';

export interface AutocompleteRequestRunParams {
  query: string;
  extra?: any;
}

// todo create axios cancel token
export abstract class AutocompleteRequestRunner {
  abstract run(params: AutocompleteRequestRunParams): Promise<Option<any>[]>;
}

export interface AutocompleteContractorsRunParams {
  query: string | number;
  extra?: CompanyShortContactorsAPI[];
}
