import { DaDataOkved, DaDataParty, DaDataPersonFio, DaDataSuggestion } from '../../types/dadata';
import { getCompanyNameInnDadata, getCompanyOkvedDadata, getPersonFioDadata } from '../dadata/requests';
import {
  CompanyMultiSearchResult,
  ContractorsSearchResult,
  OkvedDadataSearchResult,
  PersonFioDadataSearchResult,
} from './types';
import { isDefined } from '@shared/utils/is-defined';
import {
  AutocompleteContractorsRunParams,
  AutocompleteRequestRunner,
  AutocompleteRequestRunParams,
} from '@lib/Components/SearchForm/hooks/autocomplete/request-runner';
import { getRegionsApi } from '@http/endpoints/regions';
import { Option } from '@lib/Components/fields/Autocomplete/types';
import { getFkkoList } from '@http/endpoints/fkko';
import { applyMapper, eachElement } from '@http/shared';
import { searchCompaniesApi } from '@http/endpoints/company';
import { CompanyShortAPI, CompanyShortContactorsAPI } from '@http/models/api/company';
import { RegionViewModel } from '@http/models/view-models/region';

export const mapRegionToAutocompleteOption = (model: RegionViewModel): Option => ({
  value: model.id.toString(),
  label: model.name,
  hint: model.parentName ? `${model.parentType || ''} ${model.parentName}` : undefined,
});

export const isDataCompanyShortAPI = (data: CompanyShortAPI | DaDataParty): data is CompanyShortAPI => {
  return 'title' in data;
};

// todos add cancel token
export class RegionAutocompleteRequestRunner extends AutocompleteRequestRunner {
  async run(params: AutocompleteRequestRunParams): Promise<Option[]> {
    const regionsViewModel = await getRegionsApi(params.query, { size: 10 });
    return regionsViewModel.map(mapRegionToAutocompleteOption);
  }
}

// todos add cancel token
export class FkkoAutocompleteRequestRunner extends AutocompleteRequestRunner {
  async run(params: AutocompleteRequestRunParams): Promise<Option<{ id: number }>[]> {
    return applyMapper(
      getFkkoList({ q: params.query, size: 15 }),
      eachElement(
        raw =>
          ({
            label: raw.title,
            value: raw.code,
            hint: raw.code,
            extra: {
              id: raw.id,
            },
          } as Option<{ id: number }>)
      )
    );
  }
}

// todos add cancel token
export class MultiSearchCompanyAutocompleteRequestRunner extends AutocompleteRequestRunner {
  async run(params: AutocompleteRequestRunParams): Promise<Option<CompanyMultiSearchResult>[]> {
    let results: Option<CompanyMultiSearchResult>[] = [];

    const internalCompanies = await searchCompaniesApi({
      body: {
        titleOrInn: params.query,
        hasLicense: false,
      },
    });

    internalCompanies.recods.forEach(ic => {
      if (ic.inn) {
        results.push({
          value: ic.inn,
          label: `${ic.title} (${ic.inn})`,
          hint: ic.address || '',
          extra: {
            searchPlace: 'Из справочника Kritod',
            data: ic,
          },
        });
      }
    });

    if (internalCompanies.totalCount < 5) {
      const fetchMethod = await getCompanyNameInnDadata({
        query: params.query,
      });

      const dadataResults: DaDataSuggestion<DaDataParty>[] = await fetchMethod.json().then(data => data.suggestions);

      dadataResults.forEach(dc => {
        let findCompany = results.find(e => {
          return e.value === dc.data.inn;
        });

        if (!findCompany)
          results.push({
            value: dc.data.inn,
            label: `${dc.value} (${dc.data.inn})`,
            hint: dc.data?.address?.value,
            extra: {
              searchPlace: 'Глобальный поиск',
              data: dc.data,
            },
          });
      });
    }

    return results;
  }
}

export class InternalCompanySearchAutocompleteRequestRunner extends AutocompleteRequestRunner {
  async run(params: AutocompleteRequestRunParams): Promise<Option<CompanyShortAPI>[]> {
    const companies = await searchCompaniesApi({
      body: {
        titleOrInn: params.query,
        hasLicense: false,
      },
    });

    // TODO: согласовать с @daitonaaa, что отсекаем без inn и address
    return companies.recods
      .map(ic => {
        if (ic.inn && ic.address) {
          return {
            value: ic.inn,
            label: `${ic.title} (${ic.inn})`,
            hint: ic.address,
            extra: ic,
          };
        }

        return undefined;
      })
      .filter(isDefined);
  }
}

export interface FkkoDataResultExpand {
  codeId: number;
}

// todos add cancel token
export class ExpandFkkoAutocompleteRequestRunner extends AutocompleteRequestRunner {
  async run(params: AutocompleteRequestRunParams): Promise<Option<FkkoDataResultExpand>[]> {
    return applyMapper(
      getFkkoList({ q: params.query, size: 10 }),
      eachElement(
        raw =>
          ({
            label: raw.title,
            value: raw.code,
            hint: raw.code,
            extra: {
              codeId: raw.id,
            },
          } as Option<FkkoDataResultExpand>)
      )
    );
  }
}

export class DadataSearchCompanyAutocompleteRequestRunner extends AutocompleteRequestRunner {
  async run(params: AutocompleteRequestRunParams): Promise<Option<OkvedDadataSearchResult>[]> {
    const fetchMethod = await getCompanyOkvedDadata({
      query: params.query,
    });

    const dadataResults: DaDataSuggestion<DaDataOkved>[] = await fetchMethod.json().then(data => data.suggestions);

    return dadataResults.map(dc => {
      return {
        value: dc.data.kod,
        label: dc.data.name,
        hint: dc.data.kod,
        extra: {
          searchPlace: 'Глобальный поиск',
          data: dc.data,
        },
      };
    });
  }
}

export class DadataPersonFioAutocompleteRequestRunner extends AutocompleteRequestRunner {
  async run(params: AutocompleteRequestRunParams): Promise<Option<PersonFioDadataSearchResult>[]> {
    const fetchMethod = await getPersonFioDadata({
      query: params.query,
    });

    const dadataResults: DaDataSuggestion<DaDataPersonFio>[] = await fetchMethod.json().then(data => data.suggestions);

    return dadataResults.map(dc => {
      return {
        value: dc.value,
        label: dc.value,
        hint: undefined,
        extra: {
          searchPlace: 'Глобальный поиск',
          data: dc.data,
        },
      };
    });
  }
}

export class ContractorsSearchCompanyAutocompleteRunner extends AutocompleteRequestRunner {
  static defaultOptionValue = 'add-new-contactor';

  static defaultOption: Option<ContractorsSearchResult>[] = [
    {
      value: ContractorsSearchCompanyAutocompleteRunner.defaultOptionValue,
      label: '+ Новый контрагент',
    },
  ];

  static generateOption = (contractor: CompanyShortContactorsAPI): Option<ContractorsSearchResult> => {
    return {
      value: `${contractor.id}`,
      label: `${contractor.inn ? contractor.inn + ', ' : ''}${contractor.title ?? ''}`,
      hint: contractor.address || '',
      extra: {
        searchPlace: 'Глобальный поиск',
        data: contractor,
      },
    };
  };

  async run(params: AutocompleteContractorsRunParams): Promise<Option<ContractorsSearchResult>[]> {
    if (!params.extra) return ContractorsSearchCompanyAutocompleteRunner.defaultOption;

    let query = params.query;
    if (typeof query === 'number') {
      query = `${query}`;
    }

    const re = new RegExp(query.trim(), 'gi');
    const filtered = params.extra.filter(contractor => {
      const byTitle = Boolean(contractor.title) && contractor.title!.search(re) !== -1;
      const byAddress = Boolean(contractor.address) && contractor.address!.search(re) !== -1;
      const byInn = Boolean(contractor.inn) && contractor.inn!.search(re) !== -1;

      return byTitle || byAddress || byInn;
    });

    const options: Option<ContractorsSearchResult>[] = filtered.map(
      ContractorsSearchCompanyAutocompleteRunner.generateOption
    );

    options.push(ContractorsSearchCompanyAutocompleteRunner.defaultOption[0]);

    return options;
  }
}
