import MuiTextField from '@material-ui/core/TextField';
import { Chip } from '@material-ui/core';
import styled from 'styled-components';

export const TextField = styled(MuiTextField)`
  .MuiFormHelperText-contained {
    bottom: -20px;
    left: 0;
    margin: 0;
    font-size: 12px;
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    // for multiply
    min-width: 10px;
  }
`;

export const Tag = styled(Chip)`
  margin: 4px 2px !important;
  height: 26px !important;

  ${({ theme }) => theme.utils.media.largeDesktop`
    max-width: calc(60% - 6px) !important;
  `}

  .MuiChip-label {
    padding: 0 6px !important;
    font-size: 0.625rem !important;
  }

  .MuiChip-deleteIcon {
    margin: 0 5px 0 -4px !important;
    width: 16px !important;
    height: 16px !important;
  }
`;
