import { AbortController } from 'node-abort-controller';
import { httpService } from '@http/service';
import { ApiPaginateParams, applyMapper, buildQs, eachElement } from '@http/shared';
import { RegionAPI, RegionTypeEnumAPI } from '@http/models/api/region';
import { RegionViewModel } from '@http/models/view-models/region';
import { HttpOptions } from '@http/service/options';
import { Nullable } from 'types';

const mapTypeToViewModel = (type?: RegionTypeEnumAPI): Nullable<string> => {
  if (!type) {
    return null;
  }

  return (
    (
      {
        [RegionTypeEnumAPI.G]: 'г.',
        [RegionTypeEnumAPI.S]: 'село',
        [RegionTypeEnumAPI.P]: 'п.',
        [RegionTypeEnumAPI.D]: 'д.',
        [RegionTypeEnumAPI.H]: 'хутор',
        [RegionTypeEnumAPI.A]: 'аул',
        [RegionTypeEnumAPI.PGT]: 'посёлок городского типа',
      } as any
    )[type] || null
  );
};

export const getRegionsApi = (q: string, paginate?: ApiPaginateParams) => {
  const options = new HttpOptions().withCredentials(false);
  return applyMapper(
    httpService.get<RegionAPI[]>(buildQs('/region/search', { q, ...paginate }), options),
    eachElement(
      raw =>
        ({
          id: raw.id,
          name: raw.name,
          oktmo: raw.oktmo,
          parentName: raw.parent?.name,
          parentType: mapTypeToViewModel(raw.parent?.type),
        } as RegionViewModel)
    )
  );
};

export const getRegionById = async (id: number, controller: AbortController) => {
  const options = new HttpOptions().setAbortController(controller);
  const response = await httpService.get<RegionAPI>(`/region/${id}`, options);

  return {
    id: response.id,
    name: response.name,
    oktmo: response.oktmo,
    parentName: response.parent?.name,
    parentType: mapTypeToViewModel(response.parent?.type),
  } as RegionViewModel;
};
